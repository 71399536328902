<template>
  <svg width="85" height="80" viewBox="0 0 85 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M68.3179 32.8363C65.0397 16.1031 49.5105 13.3767 42.1557 14.1051C13.1567 15.9782 14.7328 41.2654 17.2544 49.07C19.7761 56.8747 29.5475 65.3038 43.4165 64.9916C57.2856 64.6794 72.4155 53.7528 68.3179 32.8363Z"
      fill="#FFD189" />
    <path
      d="M20.4003 70.62C8.98657 65.3059 0.798485 47.4311 3.52788 34.8704C14.4453 -5.4687 50.433 1.85027 60.8445 7.33354C75.9801 15.3047 93.1007 43.3247 72.5064 63.8566C51.9121 84.3884 32.1642 76.0972 20.4003 70.62Z"
      stroke="white" stroke-width="5" />
  </svg>

</template>

<style scoped>
svg path:first-of-type {
  transform: scale(1);
  transform-origin: center;
  animation: pulse 2s infinite;
}

svg path:last-of-type {
  transform: rotate(0deg);
  transform-origin: center;
  animation: rotateAnimation 10s linear infinite;
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>